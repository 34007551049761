import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import ToggleButton from '@mui/material/ToggleButton';

import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import PageListPagination from '../../components/list-pagination';
import Layout from '../../components/layout/layout';
import ProgramListHero from '../../components/program-list-hero';
import ListCard from '../../components/list-card';
import QuickLinks from '../../components/quicklinks';
import ListFilter from '../../components/list-filter';
import NoData from '../../components/no-data';
import { getProgramFilters, filterPrograms } from '../../utils/filter-util';

const ListFilters = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .displayBlock': {
    position: 'absolute',
    backgroundColor: '#fff',
    left: '5px',
    width: '100%',
    top: '72px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  '& .displayNone': {
    display: 'none'
  },
  '& .filterPopover': {
    alignItems: 'CENTER',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  '& .deskTopView': {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'blcck'
    }
  },
  '& .mobileView': {
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }

}));

const ToggleButtonCmp = styled(ToggleButton)(() => ({
  // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
  fontFamily: '"Source Sans Pro", sans-serif',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.Mui-selected': {
    background: '#E95525 !important',
    color: '#000000'
  },
  '&.Mui-selected::after': {
    content: '""',
    height: 0,
    width: 0,
    display: 'block',
    borderWidth: '10px 10px 10px 0px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    position: 'absolute',
    top: '33.5px',
    borderColor: 'transparent transparent #E95525 transparent',
    borderStyle: 'solid',
    transform: 'rotate(0deg)',
    left: '33%'
  }
}));

function ProgramPage({ data, location }) {
  const programs = data?.allContentfulPageProgram?.nodes
    .sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

  /** Essential for Responsive */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /** Essential for Responsive Ends */

  const [pagePaginationText, setPagePaginationText] = React.useState('');
  const [programList, setProgramList] = React.useState(programs.slice(0, 6));

  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [currentpageno, setCurrentpageno] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(Math.ceil(programs.length / rowsPerPage));

  /** Filter Functionality Starts ------------------ */

  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const [allButtonSelected, setAllButtonSelected] = React.useState(true);

  const onAllButtonChange = () => {
    // if selected, unselected -> show all, reset page, reset all filters
    const updatedSelectedFilters = selectedFilters.map(filter => ({
      label: filter.label,
      options: []
    }));

    setSelectedFilters(updatedSelectedFilters);

    // Set new Program List
    setProgramList(filterPrograms(programs, updatedSelectedFilters).slice(0, rowsPerPage));

    // Reset Page No.
    setCurrentpageno(1);
  };

  const onFilterChange = (label, selectedItems) => {
    const updatedSelectedFilters = [...selectedFilters];
    const updatedFilterIndex = selectedFilters.findIndex(filter => filter.label === label);
    if (updatedFilterIndex === -1) {
      updatedSelectedFilters.push({
        label,
        options: selectedItems
      });
    } else {
      updatedSelectedFilters[updatedFilterIndex].options = selectedItems;
    }

    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);

    setAllButtonSelected(false);
  };

  const removeFilter = (label, optionToBeRemoved) => {
    const updatedSelectedFilters = [...selectedFilters];
    const filterToBeUpdated = updatedSelectedFilters.find(filter => filter.label === label);
    if (filterToBeUpdated) {
      filterToBeUpdated.options = filterToBeUpdated.options.filter(option => option !== optionToBeRemoved);
    }
    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);
  };

  const checkForSelectedOptions = () => {
    const selectedOptions = selectedFilters.map(filter => filter.options).flat(2);
    if (selectedOptions.length === 0) {
      return true;
    }
    return false;
  };

  const setPagePagination = total => {
    let start = null;
    let end = null;
    const of = total;

    // Start number logic
    if (of === 0) {
      start = 0;
      end = 0;
    }

    if (currentpageno === 1 && of > 0) {
      start = 1;
      if (total < rowsPerPage) {
        end = total;
      } else {
        end = rowsPerPage;
      }
    }

    if (currentpageno > 1) {
      start = rowsPerPage * (currentpageno - 1) + 1;
      end = rowsPerPage * currentpageno;
      if (total < end) {
        end = total;
      }
    }

    setPagePaginationText(`Showing ${start}-${end} of ${of}`);
  };

  /** Filter Functionality Ends ------------------ */

  const updateCurrentpageno = (_, newPageNo) => {
    setCurrentpageno(newPageNo);
  };

  const rowsPerPageCallback = updatedRowsPerPage => {
    setCurrentpageno(1);
    setRowsPerPage(updatedRowsPerPage);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (checkForSelectedOptions()) {
      setAllButtonSelected(true);
    }

    const filteredPrograms = filterPrograms(programs, selectedFilters)
      .sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

    // Set new Programs list by setting index start and end
    setProgramList(
      filteredPrograms.slice(
        currentpageno === 1 ? 0 : (currentpageno - 1) * rowsPerPage,
        rowsPerPage * currentpageno
      )
    );

    // Set Total Pages Count
    setTotalPages(Math.ceil(filteredPrograms.length / rowsPerPage));
    setPagePagination(filteredPrograms.length);
  }, [selectedFilters, rowsPerPage, currentpageno]);

  const matches = useMediaQuery('(min-width:767px)');

  return (
    <Layout
      location={location}
      legalFootNote={data.contentfulCompListingDetails?.legalFootNote?.legalFootNote}
    >

      {/* Program Hero */}
      <ProgramListHero
        pageTitle={data.contentfulCompListingDetails.entryTitle}
        description={data?.contentfulCompListingDetails?.pageDescription}
        heroImage={data?.contentfulCompListingDetails?.heroImage}
        video={data.contentfulCompListingDetails?.video?.embeddedUrl.embeddedUrl || null}
        buttonLinks={data.contentfulCompListingDetails?.buttonLinks}
      />

      {/* Program List */}
      <Container>

        {/* Filter Section */}
        <Grid container spacing={2} sx={{ pt: 4, pl: 1 }}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h2' sx={{ mb: 2 }}>{data?.contentfulCompListingDetails.listHeading}</Typography>
            <Typography variant='headingdividerline' />
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
              <Box>
                <ToggleButtonCmp
                  sx={{ display: 'inline-block', mr: 2 }}
                  size='small'
                  selected={allButtonSelected}
                  onChange={() => {
                    setAllButtonSelected(!allButtonSelected);
                    onAllButtonChange();
                  }}
                >
                  All
                </ToggleButtonCmp>
              </Box>
              <Box>
                <ListFilters>
                  <div className='deskTopView'>
                    <ListFilter filters={getProgramFilters(programs)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                  </div>

                  <>
                    {/* <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                        onClick={() => toggleMenu()}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Box className={toggle ? 'displayBlock' : 'displayNone'}>
                        <ListFilter filters={getProgramFilters(programs)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                      </Box> */}

                    <div className='mobileView'>
                      <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                        aria-describedby={id}
                        variant='contained'
                        onClick={handleClick}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}

                      >
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          p={2}
                        >
                          <ListFilter filters={getProgramFilters(programs)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                        </Grid>
                      </Popover>
                    </div>

                  </>

                </ListFilters>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: '#E95525', height: 5, mt: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <Typography
                variant='cardcategory'
                sx={{ mr: 1 }}
              >
                {pagePaginationText}
              </Typography>
              {
                selectedFilters
                  .map(filter => filter.options
                    .map(option => (
                      <Chip
                        sx={{ mb: 0 }}
                        size='small'
                        key={option}
                        label={option}
                        variant='outlined'
                        onDelete={() => removeFilter(filter.label, option)}
                      />
                    )))
              }
            </Box>
          </Grid>
        </Grid>

        {programList && programList.length > 0 && (
          <Grid container spacing={2} sx={{ padding: '20px 0 50px' }}>
            {
              programList
                .map(program => <ListCard details={program} link={`/program/${program.slug}`} key={program.contentful_id} />)
            }

            {/* Pagination */}
            <Grid container item justifyContent='flex-start' alignItems='center'>
              <Grid xs={12} sm={12} md={4}>
                <PageListPagination totalRows={programs.length} rowsPerPageCallback={rowsPerPageCallback} />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4} alignItems='center' justifyContent='center' container>
                <Pagination
                  count={totalPages}
                  page={currentpageno}
                  onChange={updateCurrentpageno}
                  variant='outlined'
                />
              </Grid>
              <Grid xs={0} sm={4} />
            </Grid>
          </Grid>
        )}

        {programList && programList.length === 0 && (
          <NoData name='Referral Sources' />
        )}

      </Container>

      {/* Quick Links */}
      <Container>
        {data.contentfulCompListingDetails?.quickLinks?.title && (
          <>
            <Typography variant='h2' component='h2' sx={{ mb: 2 }}>{data.contentfulCompListingDetails?.quickLinks?.title}</Typography>
            <Typography variant='headingdividerline' />
          </>
        )}
        <Grid container spacing={1} sx={{ pt: 4, mb: 4 }}>
          {
            data.contentfulCompListingDetails?.quickLinks?.quickLink && <QuickLinks data={data.contentfulCompListingDetails?.quickLinks} />
          }
        </Grid>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ProgramListing {
    contentfulCompListingDetails(type: {eq: "Program"}) {
      contentful_id
      entryTitle
      listHeading
      heroImage {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
      video {
        embeddedUrl {
          embeddedUrl
        }
      }
      pageDescription {
        raw
      }
      type
      quickLinks {
        ...programQuickLinks
      }
      buttonLinks {
        ...programButtonLink
      }
      legalFootNote {
        legalFootNote
      }
    }
    allContentfulPageProgram(
      sort: { fields: updatedAt, order: DESC }
      filter: {isDisabled: {ne: true}}
    ) {
      totalCount
      nodes {
        contentful_id
        programShortTitle
        slug
        category
        participatingBrands {
          brandName
        }
        marketingMaterial {
          contentful_id
          category
        }
        programThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            placeholder: BLURRED
          )
        }
        createdAt(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        updatedAt_: updatedAt # Timestamp
      }
    }
  }
  fragment programQuickLinks on ContentfulCompQuickLinkList {
    title
    quickLink {
      title
      logo {
        file {
          url
        }
        gatsbyImageData(
          aspectRatio: 1
          jpegProgressive: true
          formats: WEBP
          placeholder: BLURRED
        )
      }
      description {
        description
      }
      actionButton {
        ...programButtonLink
      }
    }
  }
  fragment programButtonLink on ContentfulRanCompLink {
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }
`;

ProgramPage.propTypes = {
  location: PropTypes.shape.isRequired
};

export default ProgramPage;
